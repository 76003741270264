import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { PRIMARY_COLOR } from "../../constants/colors";
import { fromJS } from "immutable";
import {
  PRODUCT_TYPE_IDS,
  productsByProductId,
} from "../../data/products";
import getMaxResolutionForDimensions from "../../lib/get-max-resolution-for-dimensions";
import { pickImages } from "../../lib/file-uploader";
import Button from "../Button/Button";
import HtmlRenderer from "../HtmlRenderer/HtmlRenderer";
import EditorCropperModal from "../Editor/EditorCropperModal";
import TextEditor from "../Editor/TextEditor";
import Modal from "../Modal/Modal";
import MainContent from "../MainContent/MainContent";
import postsnapApi from "../../lib/apis/postsnap";

class RenderEditor extends Component {
  static propTypes = {
    itemDataUrl: PropTypes.string.isRequired,
    width: PropTypes.number,
    debug: PropTypes.bool,
    page: PropTypes.string,
    orderReference: PropTypes.string,
    recipientKey: PropTypes.string
  };

  static defaultProps = {
    width: 600,
    debug: false
  };

  state = {
    itemData: null,
    rendering : false,
    saving : false,
    saved: false,
    isCroppingFullPhoto: false,
    selectedRegionIndex: null
  };

  async componentWillMount() {
    const itemDataResponse = await axios.get(this.props.itemDataUrl);
    console.log("itemDataResponse", itemDataResponse.data)
    this.setState({
      itemData: itemDataResponse.data,
    });
  }

  pickImageFromUploadcare = async (source, amount) => {
    try {
      const product = fromJS(productsByProductId.get(this.state.itemData.productId));
      let maxResolution = getMaxResolutionForDimensions({
        width: product.get("width"),
        height: product.get("height"),
        dpi: product.get("dpi"),
      });

      maxResolution = false;
      let uploadedImages = await pickImages({
        amount: 1,
        source,
        maxResolution,
        onTotalProgressUpdate: () => {},
      });
      let uploadedImage = uploadedImages;
      let newImage = {
        src: {
          lowResUrl: uploadedImage.lowResUrl,
          highResUrl: uploadedImage.highResUrl,
          uploadcareUuid: uploadedImage.uuid,
        },
        cropData: null, //null, cropData
      };
      
      const newItemData = { ...this.state.itemData };
      const layers = newItemData.layers;

      // Update Layout
      const layout = layers[0].config.layout;
      const selectedRegion = layout[0];
      selectedRegion.image = newImage
      //image.cropData = cropData;

      //Update Template
      const placeholder = layers[0].config.template.placeholders.find(placeholder => placeholder.id === this.state.templatePhotoEdit.placeholderId);
      placeholder.properties.imageData = newImage


      this.setState({
        itemData: newItemData,
        isCropperModalVisible: false,
      });

      this.closeUploadModal();
    } catch (err) {
      console.log("Cancelling upload:", err);
    }
  };

  handleSelectRegionInPhotoLayer = (layerId, regionIndex) => {
    console.log("regionIndex", regionIndex)
    this.setState({
      selectedLayerId: layerId,
      selectedRegionIndex: regionIndex,
      isCropperModalVisible: true,
      isCroppingFullPhoto: true,
    });
  };

  saveKey = async () => {
    //const response = await postsnapApi.renderService.renderItemAsync(this.state.itemData)
    this.setState({
      saving: true
    }, async () => {
      const response = await postsnapApi.editRender.save(this.props.recipientKey, this.state.renderResponse.await_s3_key, this.state.renderResponse.await_low_res_s3_key, this.state.renderResponse.jsonURL, this.props.layerConfigId)
      console.log("response", response)
      this.setState({
        saving: false,
        saved: true
      })
    })
    
  }

  addBorder = () => {
    const newItemData = { ...this.state.itemData };
    const layers = newItemData.layers;
    const layer = layers[0]
    if (layer.config.border?.style?.showBorder === false){
      layer.config.border.style.showBorder = true
      // layer.config.border = {
      //   style: {
      //     type: "color",
      //     color: "#CCC",
      //     showBorder: true,
      //   },
      //   thickness:0.05,
      // }
    } else{
      layer.config.border.style.showBorder = false
      // layer.config.border = {
      //   style: { showBorder: false },
      //   thickness: 0,
      // }
    }
    
  
    this.setState({
      itemData: newItemData,
    });

  }

  rerender = async () => {
    console.log("Rerendering...")

    this.setState({
      rendering: true
    }, async () => {
      const response = await postsnapApi.renderService.renderItemAsync(this.state.itemData)
      const imageUrl = `https://eclipse-engine.s3.amazonaws.com/${response.data.await_s3_key}`;

      const loadImage = () => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          console.log("image loaded...")
          this.setState({
            renderResponse: response.data,
            rendered: true
          }, () => {
            this.setState({
              rendering: false
            })
          });
        };

        image.onerror = () => {
          // Image failed to load
          console.error('Image failed to load:', image.src);
          console.log("Retrying...")
          setTimeout(loadImage, 1000);
          // Handle the error as needed, you can set a state flag or show a placeholder image
        };
      };
      loadImage();
      console.log("response", response.data)
    })

    
  }

  onTextEditSave = (updatedPlaceholder, font = null, keepOpen = false) => {
    console.log("updatedPlaceholder", updatedPlaceholder)
    const newItemData = { ...this.state.itemData };
    const layers = newItemData.layers;

    const textPlaceholderIndex = layers[0].config.template.placeholders.findIndex(placeholder => placeholder.type === "TEXT_PLACEHOLDER");

  // If a matching placeholder is found, update it

    if (textPlaceholderIndex !== -1) {
      const placeholders = [...layers[0].config.template.placeholders]; // Create a shallow copy of the placeholders array
      placeholders[textPlaceholderIndex] = updatedPlaceholder; // Update the specific placeholder
      layers[0].config.template.placeholders = placeholders; // Update the entire array in the layers config
    }

    console.log("Font", font)

    if (font){
      newItemData.product_options.fontFamily = font
    }


    this.setState({
      itemData: newItemData,
    });
  }

  handleCropperSave = (cropData) => {
    const regionIndex = this.state.selectedRegionIndex || 0
    console.log("Saving Crop Data", cropData)

    // Create a shallow copy of itemData
    const newItemData = { ...this.state.itemData };
    const layers = newItemData.layers;

    // Update Layout
    const layout = layers[0].config.layout;
    const selectedRegion = layout[regionIndex];
    const image = selectedRegion.image;
    image.cropData = cropData;

    //Update Template
    if (layers[0].config.template){
      const placeholder = layers[0].config.template.placeholders.find(placeholder => placeholder.id === this.state.templatePhotoEdit.placeholderId);
      placeholder.properties.imageData.cropData = cropData
    }
    
    this.setState({
      itemData: newItemData,
      isCropperModalVisible: false,
    });
  }

  handleReplaceImage = index => {
    console.log("here", index)
    this.setState({
      isCropperModalVisible: false,
      isUploadModalVisible: true,
      isReplacingImage: true,
      replacingImageIndex: index,
    });
  };

  closeUploadModal = () => {
    this.setState({
      isUploadModalVisible: false,
    });
  };

  handleClickPhoto = (
    layerId,
    regionIndex,
  ) => {
    console.log({ layerId, regionIndex });
    this.setState({isCropperModalVisible: true})
    // template.placeholders.forEach(placeholder => {
    //   if (placeholder.id === placeholderId) {
    //     this.setState({
    //       templatePhotoEdit: {
    //         placeholderId,
    //         pageNumber,
    //         template,
    //       }
    //     }, () => {
         
    //     });
    //   }
    // });
  };

  
  templateCallback = (
    placeholderId,
    pageNumber,
    template,
  ) => {
    console.log({ placeholderId, pageNumber, template });
    template.placeholders.forEach(placeholder => {
      if (placeholder.id === placeholderId) {
        this.setState({
          templatePhotoEdit: {
            placeholderId,
            pageNumber,
            template,
          }
        }, () => {
          this.setState({isCropperModalVisible: true})
        });
      }
    });
  };


  render() {
    if (!this.state.itemData) {
      return null;
    }

    const item = this.state.itemData;
    const page = item.layers[0].page;
    let width = this.props.width;

    const template = this.state.itemData.layers[0].config.template
    let cropperRatio = 1;
    let imageForCropper = null
    let textPlaceholder = null
    const productWidth = this.state.itemData.productDimensions.width
    const productHeight = this.state.itemData.productDimensions.height
    

    const uploadModal = this.state.itemData && (
      <Modal
        key="uploadcare-selection-modal"
        isOpen={this.state.isUploadModalVisible}
        onClose={this.closeUploadModal}
        title={"Upload Photos"}
        leftAction={
          <Button
            priority="tertiary"
            theme="muted"
            label="Cancel"
            onClick={() => this.closeUploadModal()}
          />
        }
      >
        <MainContent scrollable={false} padded centeredVertically>
          <div className="restricted-width-modal-content" style={{ marginTop: "-50px" }}>
            <Button
              block
              label="Upload From Device"
              icon="phone"
              theme="dark-blue"
              onClick={() => this.pickImageFromUploadcare("file", 1)}
            />
          </div>
        </MainContent>
      </Modal>
    );

    
    const cropperModalProps = {};

    const indexOfPhotoLayer = this.state.itemData.layers.findIndex(layer => layer.type === "Layer::Photo");
    const photoRect = this.state.itemData.layers[indexOfPhotoLayer].config.rect
    let productDimensionsForCropper = Object.assign({}, this.state.itemData.productDimensions); 
    //We want the photo region dimensions and not the product dimensions
    productDimensionsForCropper.width = productDimensionsForCropper.width * photoRect.width;
    productDimensionsForCropper.height = productDimensionsForCropper.height * photoRect.height;

    cropperModalProps.ratio =
    (productDimensionsForCropper.width + productDimensionsForCropper.bleed.left + productDimensionsForCropper.bleed.right) /
    (productDimensionsForCropper.height + productDimensionsForCropper.bleed.top + productDimensionsForCropper.bleed.bottom);
        
    if (this.state.itemData.isRotated){
      cropperModalProps.ratio = 
      (productDimensionsForCropper.height + productDimensionsForCropper.bleed.top + productDimensionsForCropper.bleed.bottom) /
      (productDimensionsForCropper.width + productDimensionsForCropper.bleed.left + productDimensionsForCropper.bleed.right)
    }

    if (this.state.selectedRegionIndex !== null && this.props.productType !== "PHOTOBOOKS"){
      if (this.props.productType !== "GREETING"){
        cropperRatio = (productDimensionsForCropper.width * this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex].width) /
        (productDimensionsForCropper.height * this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex].height);
      }

      imageForCropper = fromJS(this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex].image)
      cropperModalProps.cropData = this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex].cropData
      if (this.state.itemData.isRotated){
        if (this.props.productType !== "GREETING"){
          cropperRatio =
          (productDimensionsForCropper.height * this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex].height) /
          (productDimensionsForCropper.width * this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex].width);
        }
      }

    } else{
      imageForCropper = fromJS(this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex || 0].image)
      cropperModalProps.cropData = this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex || 0].cropData

      // If no template on book (full photo) then use the ratio of the product
      if (this.props.productType === "PHOTOBOOKS"){
        if (template){
          textPlaceholder = template.placeholders.find(placeholder => placeholder.type === "TEXT_PLACEHOLDER");
          if (this.state.templatePhotoEdit){
    
            const position = template.placeholders.find(placeholder => placeholder.id === this.state.templatePhotoEdit.placeholderId).position
    
            const placeholderWidth = (position.topRight[0] - position.topLeft[0]) * productWidth
            const placeholderHeight = (position.bottomRight[1] - position.topRight[1]) * productHeight
    
            cropperRatio = placeholderWidth/placeholderHeight
            imageForCropper = fromJS(template.placeholders.find(placeholder => placeholder.id === this.state.templatePhotoEdit.placeholderId).properties.imageData);
    
          }
        } else {
          cropperRatio = productWidth/productHeight
          imageForCropper = fromJS(this.state.itemData.layers[0].config.layout[0].image)
    
          if (this.state.selectedRegionIndex !== null){
            imageForCropper = fromJS(this.state.itemData.layers[0].config.layout[this.state.selectedRegionIndex].image)
          }
          cropperRatio = cropperModalProps.ratio
        }
      }
    }

    console.log("this.state.selectedRegionIndex", this.state.selectedRegionIndex)
    console.log("cropperRatio", cropperRatio)
    console.log("imageForCropper", imageForCropper)
    
    const bookCropperModal = (
      <EditorCropperModal
        key="render-cropper-modal"
        onHandleReplaceImage={() => this.handleReplaceImage(this.state.pageNumberToCrop)}
        onHandleReplaceImageInTemplate={() => console.log("foo")}
        isOpen={this.state.isCropperModalVisible}
        imgUrl={imageForCropper && imageForCropper.getIn(["src", "highResUrl"])+`/-/resize/800x/-/quality/smart_retina/`}
        cropData={
          imageForCropper && imageForCropper.get("cropData")
            ? imageForCropper.get("cropData").toJS()
            : null
        }
        ratio={cropperRatio}
        onClose={() => this.setState({ isCropperModalVisible: false })}
        onSave={this.handleCropperSave}
        isPhotoBook={true}
        renderEditor={true}
        
      />
    )

    const nonBookCropperModal = (
      <EditorCropperModal
        {...cropperModalProps}
        key="render-cropper-modal"
        onHandleReplaceImage={() => this.handleReplaceImage(this.state.pageNumberToCrop)}
        onHandleReplaceImageInTemplate={() => console.log("")}
        isOpen={this.state.isCropperModalVisible}
        imgUrl={imageForCropper && imageForCropper.getIn(["src", "highResUrl"])+`/-/resize/800x/-/quality/smart_retina/`}
        cropData={
          imageForCropper && imageForCropper.get("cropData")
            ? imageForCropper.get("cropData").toJS()
            : null
        }
        onClose={() => this.setState({ isCropperModalVisible: false })}
        onSave={this.handleCropperSave}
        isPhotoBook={false}
        renderEditor={true}
        ratio={cropperRatio}
        
      />
    )

    console.log("this.state.itemData.isRotated", this.state.itemData.isRotated)

    return (
      <React.Fragment>
        {this.props.productType === "PHOTOBOOKS" ? (
          bookCropperModal
         ) : (
          nonBookCropperModal
         )
        }
        {uploadModal}
        <div style={{
          padding: '20px',
          backgroundColor: '#e6e6e6'
        }}>
          <div style={{textAlign: 'center', paddingBottom: '20px'}}>
            {this.props.productType === "PHOTOBOOKS" && (
              <>
                <h2>Editing {this.props.page} of {this.props.orderReference}</h2>
                <hr/>
              </>
            )}
            {imageForCropper && (
              <a href={imageForCropper.getIn(["src", "highResUrl"]).replace("/-/autorotate/yes/", "")} alt="Original" target="_blank">See original file</a>
            )}
          </div>
          {this.props.productType === "PHOTOBOOKS" && (
            <HtmlRenderer
              debug={this.props.debug}
              width={width || 800}
              page={page}
              item={item}
              isInteractive={true}
              previewMode
              screenshotMode
              showBorderCanvas={item.productTypeId === PRODUCT_TYPE_IDS.PHOTO_MAGAZINE ? false : true}
              onSelectRegionInPhotoLayer={this.handleSelectRegionInPhotoLayer}
              reRender={true}
              templateCallback={this.templateCallback}
              //onSelectRegionInPhotoLayer={this.handleClickPhoto}
            />
          )}
          {this.props.productType !== "PHOTOBOOKS" && (
            <HtmlRenderer
              width={500}
              page={page}
              item={item}
              isInteractive={true}
              previewMode={true}
              onSelectRegionInPhotoLayer={this.handleSelectRegionInPhotoLayer}
              templateCallback={this.templateCallback}
            //onSelectRegionInPhotoLayer={this.handleClickPhoto}
          />
          )}
          <div>
            <div>
              {textPlaceholder && (
                <TextEditor
                defPlaceholder={textPlaceholder}
                toUpdate={textPlaceholder}
                onPage={page}
                item={fromJS(item)}
                setGlobalTextFont={() => {}}
                forCover={page === 0}
                isEditingCoverPage={page === 0}
                onSave={this.onTextEditSave}
                renderEdit={true}
              />
              )}
              
            </div>
          </div>
          {this.props.productType === "PHOTOBOOKS" && (
            <div style={{textAlign: 'center', paddingBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px'}}>
              <Button
                theme="dark-blue"
                priority="secondary"
                label={"Toggle Border"}
                onClick={this.addBorder}
                style={{width: '200px', margin: '0 auto'}}
              />
            </div>
          )}
          {this.props.productType !== "PHOTOBOOKS" && (
            <div style={{textAlign: 'center', paddingBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px'}}>
              <p>Tap the photo above to crop it</p>
            </div>
          )}
          <div style={{textAlign: 'center', paddingBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px'}}>
            <Button
              theme="dark-blue"
              label={this.state.rendering ? "Rendering ... please wait" : "Generate Render File"}
              onClick={this.rerender}
              style={{width: '250px', margin: '0 auto'}}
              disabled={this.state.rendering}
              loading={this.state.rendering}
            />
            {this.state.rendering && (
              <>
                <div style={{marginTop: '10px', marginLeft: '10px'}}>
                <BarLoader color={PRIMARY_COLOR} />
                </div> 
                <div style={{padding: '10px', background: '#ffeb3b', borderRadius: '10px', maxWidth:'600px', margin: '0 auto', marginTop: '10px'}}>
                  <p>Your changes are being rendered to a file, please wait, this may take up to 30 seconds. The rendered file will appear below shortly. If you are happy with the file, proceed to Save Render below.</p>
                </div>
              </>
            )}
            

            {this.state.renderResponse && (
              <>
                <div style={{margin: '20px', padding: '10px'}}>
                  <img alt="render" src={`https://eclipse-engine.s3.amazonaws.com/${this.state.renderResponse.await_s3_key}`} style={{width: '600px'}}/>
                  <br/>
                  <a
                    href={`https://eclipse-engine.s3.amazonaws.com/${this.state.renderResponse.await_s3_key}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'black',
                      textDecoration: 'underline',
                      marginTop: '10px',
                      display: 'block'
                    }}
                    >
                      Preview full-size render
                    </a>
                </div>
                
                <Button
                  theme="dark-blue"
                  label={this.state.saving ? "Saving to Eclipse ..." : "Save Render Above To Eclipse"}
                  onClick={this.saveKey}
                  style={{width: '250px', margin: '0 auto'}}
                  disabled={this.state.saving}
                  loading={this.state.saving}
                />
                {this.state.saved && (
                  <div style={{padding: '10px', background: '#ffeb3b', borderRadius: '10px', maxWidth:'600px', margin: '0 auto', marginTop: '10px'}}>
                    <p>Your render has been saved to Eclipse and the order is being regenerated with your changes... this may take a few moments</p>
                  </div>
                )}
                {/* <pre>{JSON.stringify(this.state.renderResponse, null, 2) }</pre> */}
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default RenderEditor;
